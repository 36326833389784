<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getCasinoBetList"><i class="fa fa-refresh"></i></div>
            <el-input type="text" size="mini" v-model="honorCasinoBet.nickname" placeholder="닉네임을 입력하세요" style="width: 150px"></el-input>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="getCasinoBetList">검색
            </el-button>
            <el-select v-model="honorCasinoBet.betResult" @change="getCasinoBetList" size="mini"
                       placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="15" label="당첨" :value="managerConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option key="16" label="낙첨" :value="managerConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
            </el-select>
            <el-select v-model="honorCasinoBet.type" @change="getCasinoBetList" size="mini"
                       placeholder="종류선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="17" label="전체" :value="null">전체</el-option>
                <el-option key="18" label="카지노" :value="managerConst.CASINO">카지노</el-option>
                <el-option key="19" label="슬롯" :value="managerConst.SLOT">슬롯</el-option>
            </el-select>

<!--            <el-button type="danger" size="mini" style="margin-left: 20px" @click="synsData(20)">동기화(20분전)</el-button>-->
<!--            <el-button type="danger" size="mini" style="margin-left: 20px" @click="synsData(60)">동기화(60분전)</el-button>-->
<!--            <el-button type="danger" size="mini" style="margin-left: 20px" @click="synsData(120)">동기화(120분전)</el-button>-->
<!--            <el-button type="danger" size="mini" style="margin-left: 20px" @click="synsData(240)">동기화(240분전)</el-button>-->

        </div>

        <div class="data" style="overflow-y: scroll;max-height: 750px">
            <div style="padding: 5px 0;font-size: 14px">
                <span style="padding-left: 20px;">총건수:{{totalBetCount|comma}}건</span>
                <span style="padding-left: 20px;">총베팅금액:{{totalBetCash|comma}}원 </span>
                <span style="padding-left: 20px;">당첨금액:{{totalBetWinCash|comma}} 원 </span>
                <span style="padding-left: 20px;">손이익:{{(totalBetCash - totalBetWinCash)|comma}} 원 </span>
            </div>
            <table style="width: 100%">
                <tr>
                    <th style="width: 7%">ID</th>
                    <th style="width: 10%">닉네임</th>
                    <th style="width: 12%">Vendor</th>
                    <th style="width: 6%">gtype</th>
                    <th style="width: 12%">Title</th>
                    <th style="width: 15%">Round</th>
                    <th style="width: 7%">베팅금액</th>
                    <th style="width: 8%">당첨금액</th>
                    <th style="width: 5%">베팅결과</th>
                    <th style="width: 11%">베팅시간</th>
                    <th style="width: 7%">파트너정산</th>

                </tr>
                <tr v-for="item in honorCasinoBetList">
                    <td>
                        {{item.id}}
                    </td>
                    <td>
                        <el-button size="mini" type="success" @click="editUser(item.userId,managerConst.MODE_EDIT)">
                            {{item.nickname}}
                        </el-button>
                    </td>
                    <td>
                        {{item.vendor}}
                    </td>
                    <td>
                        {{item.gtype}}
                    </td>

                    <td>
                        {{item.title}}
                    </td>
                    <td>
                        {{item.ground}}
                    </td>
                    <td>
                        <span>{{item.betamount | comma}}</span>
                    </td>
                    <td>
                        <span>{{item.winamount | comma}}</span>
                    </td>
                    <td>
                        <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WIN" class="badge badge-primary">당첨</span>
                    </td>
                    <td>{{item.betTimeKr | datef('MM월DD일 HH:mm:ss')}}</td>
                    <td>
                        <span v-if="item.givebacked == managerConst.YES" class="badge badge-warning">완료</span>
                    </td>

                </tr>
            </table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="50"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "ManagerCasinoBetHonor",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                honorCasinoBet: {partnerId: null},
                honorCasinoBetList: [],
                pageNum: 1,
                pageSize: 30,
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetWinCash: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        methods: {
            getCasinoBetList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.honorCasinoBet.startDate = this.startDate
                this.honorCasinoBet.endDate = this.endDate
                getHonorCasinoBetlist(this.honorCasinoBet, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.data.pageTotal;
                    this.honorCasinoBetList = res.data.data.list;
                    this.totalBetCount = res.data.data.pageTotal;
                    this.totalBetCash = res.data.data.statistic.betamount;
                    this.totalBetWinCash = res.data.data.statistic.winamount;
                    loadingInstance.close();
                })

            },
            synsData(time){

                this.$confirm('카지 베팅내역을  동기화 하시겠습니까?(빈번하게 동기화시 카지노 아이디가 차단맞을수 있습니다)', '카지노 베팅내역 동기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    sysData(time).then(res=>{
                        loadingInstance.close();
                        this.getCasinoBetList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });

            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCasinoBetList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCasinoBetList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.leisureGameBet.partnerId = parterId
                this.search();
            },
        },
        created() {
            this.startDate = null
            this.endDate = null
            this.getCasinoBetList()
        },
    }
</script>

<style scoped>

</style>